import React from 'react'
import {Link} from 'gatsby'
import shape1 from '../../assets/images/shape/shape1.svg'
import shape2 from '../../assets/images/shape/shape2.png'
import shape3 from '../../assets/images/shape/shape3.png'
import { useIntl } from "gatsby-plugin-intl"

const PageBanner = ({pageTitle, homePageUrl, homePageText, activePageText, other=null}) => {
    const intl = useIntl();
    return (
        <div className="page-title-area">
            <div className="container">
                <div className="page-title-content">
                    <h2>{pageTitle}</h2>
                    <ul>
                        <li>
                            <Link to={`/${intl.locale}${homePageUrl}`}>
                                {homePageText}
                            </Link>
                        </li>
                        
                        {other ? (
                            <>
                            <li>
                                <Link to={`/${intl.locale}${other.previousPageUrl}`}>
                                    {activePageText}
                                </Link>
                            </li>
                            <li>{other.contentActivePage}</li>
                            </>
                        ) : (<li>{activePageText}</li>)}
                    </ul>
                </div>
            </div>

            <div className="shape-img1">
                <img src={shape1} alt="banner" />
            </div>
            <div className="shape-img2">
                <img src={shape2} alt="banner" />
            </div>
            <div className="shape-img3">
                <img src={shape3} alt="banner" />
            </div>
        </div>
    )
}

export default PageBanner;