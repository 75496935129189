import React, { useState } from 'react'
import starIcon from '../../assets/images/star-icon.png'
import contact from '../../assets/images/contact.png'
import {
    URL_CONTACT_FORM
} from "../../constants/configs"
import { useIntl } from "gatsby-plugin-intl"

const ContactForm = () => {
    const [sendLoading, setSendMessageStatus] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone_number, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const onSendMessage = (e) => {
        e.preventDefault();
        if(sendLoading === 'success') return;
        setSendMessageStatus('loading')
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "name": name,
                "email": email,
                "phone": phone_number === '' ? null : phone_number,
                "message": message
            })
        };
        fetch(URL_CONTACT_FORM, requestOptions)
        .then(async response => {
            setSendMessageStatus('success');
            setName('')
            setEmail('')
            setPhone('')
            setMessage('')
        })
        .catch(error => {
            setSendMessageStatus('')
        })
    }
    const intl = useIntl()
    return (
        <section className="contact-area pb-100">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="contact" /> 
                        {intl.formatMessage({ id: "contact.ContactForm.SubTitle" })}
                    </span>
                    <h2>{intl.formatMessage({ id: "contact.ContactForm.H2SubTitle" })}</h2>
                    <p>{intl.formatMessage({ id: "contact.ContactForm.PSubTitle" })}</p>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="contact-image">
                            <img src={contact} alt="contact" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="contact-form">
                            <form id="contactForm" action="#" onSubmit={(e) => onSendMessage(e)}>
                                <div className="row">
                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input 
                                                type="text" 
                                                name="name" 
                                                value={name} 
                                                onChange={(e) => setName(e.target.value)}
                                                className="form-control" 
                                                required 
                                                placeholder={intl.formatMessage({ id: "contact.ContactForm.YourName" })} />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input 
                                                type="email" 
                                                name="email" 
                                                value={email} 
                                                onChange={(e) => setEmail(e.target.value)}
                                                className="form-control" 
                                                required 
                                                placeholder={intl.formatMessage({ id: "contact.ContactForm.YourEmail" })} />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <input 
                                                type="text" 
                                                name="phone_number" 
                                                value={phone_number} 
                                                onChange={(e) => setPhone(e.target.value)}
                                                className="form-control" 
                                                placeholder={intl.formatMessage({ id: "contact.ContactForm.YourPhone" })} />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <textarea 
                                                name="message" 
                                                value={message} 
                                                onChange={(e) => setMessage(e.target.value)}
                                                className="form-control" 
                                                cols="30" 
                                                rows="6" 
                                                required 
                                                placeholder={intl.formatMessage({ id: "contact.ContactForm.WriteMessage" })} />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <button 
                                            disabled={sendLoading === 'success'} 
                                            type="submit" 
                                            className={`default-btn${sendLoading === 'success' ? "btn-green" : ""}`}
                                        >
                                            {sendLoading === '' && <><i className="flaticon-notebook"></i>{intl.formatMessage({ id: "contact.ContactForm.SendMessage" })}</>}
                                            {sendLoading === 'loading' && <><i className="flaticon-refresh loading-rotate"></i>{intl.formatMessage({ id: "contact.ContactForm.SendMessage" })}</>}
                                            {sendLoading === 'success' && <><i className="flaticon-tick"></i>{intl.formatMessage({ id: "contact.ContactForm.SendSuccess" })}</>} 
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactForm