import React from 'react'
import Layout from "../components/App/Layout"
import NavbarTwo from "../components/App/NavbarTwo"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ContactInfo from '../components/Contact/ContactInfo'
import ContactForm from '../components/Contact/ContactForm'
import { useIntl } from "gatsby-plugin-intl"
const Contact = () => {
    const intl = useIntl()
    return (
        <Layout>
            <NavbarTwo bgWhite={false}/>
            <PageBanner
                pageTitle={intl.formatMessage({ id: "contact" })} 
                homePageText={intl.formatMessage({ id: "home" })}
                homePageUrl="/" 
                activePageText={intl.formatMessage({ id: "contact" })} 
            />
            <ContactInfo />
            <ContactForm />
            <Footer />
        </Layout>
    );
}

export default Contact