import React from 'react'
import { useIntl } from "gatsby-plugin-intl"
const ContactInfo = () => {
    const intl = useIntl()
    return (
        <div className="contact-info-area pt-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bx-map'></i>
                            </div>
                            <div className="icon">
                                <i className='bx bx-map'></i>
                            </div>
                            <h3>{intl.formatMessage({ id: "contact.ContactInfo.Name1" })}</h3>
                            <p>{intl.formatMessage({ id: "contact.ContactInfo.Content1" })}</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bx-phone-call'></i>
                            </div>
                            <div className="icon">
                                <i className='bx bx-phone-call'></i>
                            </div>
                            <h3>{intl.formatMessage({ id: "contact.ContactInfo.Name2" })}</h3>
                            <p>{intl.formatMessage({ id: "contact.ContactInfo.Content2" })}: <a href={`tel:${intl.formatMessage({ id: "home.Footer.Address.Phone" })}`}>{intl.formatMessage({ id: "home.Footer.Address.Phone" })}</a></p>
                            <p>E-mail: <a href={`mailto:${intl.formatMessage({ id: "home.Footer.Address.Email" })}`}>{intl.formatMessage({ id: "home.Footer.Address.Email" })}</a></p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bx-time-five'></i>
                            </div>
                            <div className="icon">
                                <i className='bx bx-time-five'></i>
                            </div>
                            <h3>{intl.formatMessage({ id: "contact.ContactInfo.Name3" })}</h3>
                            <p>{intl.formatMessage({ id: "contact.ContactInfo.Content3" })}: 09:00 - 18:00</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactInfo